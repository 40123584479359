function NotFound() {
    return (
        <>
            <div className="Content">
                <div className="Main">
                    <img className="PixelImg" alt="Not Found!" width={400} src='/images/notfound.png'></img>
                </div>
            </div>
        </>
    )
}

export default NotFound;